import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import axios from "axios"
import { store as tStore, setT } from "../../Store/Store"
import StaticPage from "../../StaticPage/StaticPage"
import { navigate } from "gatsby"
import Loading from "../../Loading/Loading"

export default class Confirm extends Component {
  state = {
    formValues: {},
  }

  componentDidMount() {
    const { location } = this.props
    let { formValues } = this.state

    if (!location) {
      navigate("/")
      return
    }

    const query = location.search.substr(1)
    const parts = query.split("id=")
    if (parts.length !== 2) {
      navigate("/")
      return
    }

    formValues.id = parts[1].split("&")[0]
    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.storeUpdate()
    this.setState({
      formValues: formValues,
    })
  }

  componentWillUnmount() {
    Utils.CancelToken(this.cancelToken)
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const storeState = tStore.getState()
    if (storeState.t) {
      this.setState({
        t: storeState.t,
      })
      this.confirm()
    }
  }

  confirm = () => {
    const { t, formValues, confirmed } = this.state

    if (confirmed) {
      return
    }

    if (!t) {
      console.warn("Cannot confirm subscription, not authenticated")
      return
    }

    if (localStorage) {
      localStorage.setItem("newsletter-notification", "true")
    }

    this.cancelToken = axios.CancelToken.source()
    axios
      .put(
        `/enjocommerce/v1/users/${formValues.id}`,
        {
          confirmed: true,
        },
        Utils.RequestHeaders(t, this.cancelToken)
      )
      .then(() => {
        this.setState({
          confirmed: true,
        })
        Utils.DoGoogleTagEvent({
          dataLayer: {
            event: "signup",
          },
        })
      })
      .catch(err => {
        if (Utils.LogAxiosError(err)) {
          return
        }
        if (err.response) {
          if (err.response.status == 401) {
            tStore.dispatch(setT(""))
          }
        }
        this.setState({
          confirmed: true,
          error: true,
        })
      })
  }

  empty = () => {
    return ""
  }

  getOverlay = () => {
    const { createOverlay, createErrorOverlay } = this.props
    const { formValues, error, confirmed } = this.state

    if (!confirmed) {
      return this.getLoading
    }

    if (error) {
      return createErrorOverlay
    }
    if (formValues.id && confirmed) {
      return createOverlay
    }
    return this.empty
  }

  getContentList = () => {
    const {
      createContentList,
      createEmptyContentList,
      createErrorContentList,
    } = this.props
    const { formValues, error, confirmed } = this.state

    if (error) {
      return createErrorContentList
    }
    if (formValues.id && confirmed) {
      return createContentList
    }
    return createEmptyContentList
  }

  getLoading = () => {
    return <Loading />
  }

  render() {
    const {
      contentMap,
      commonContentMap,
      language,
      originPage,
      title,
      description,
      breadCrumbs,
      location,
      img,
      keywords,
    } = this.props

    return (
      <StaticPage
        img={img}
        language={language}
        originPage={originPage}
        location={location}
        contentMap={contentMap}
        commonContentMap={commonContentMap}
        title={title}
        description={description}
        breadCrumbs={breadCrumbs}
        createContentFun={this.getContentList()}
        createOverlayFun={this.getOverlay()}
        keywords={keywords}
      />
    )
  }
}
